import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  Observable,
  split,
  TypePolicies,
} from "@apollo/client";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {setContext} from "@apollo/client/link/context";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {getMainDefinition} from "@apollo/client/utilities";
import fetch from "cross-fetch";
import {createClient, Client, ClientOptions} from "graphql-ws";
// import ApolloLinkTimeout from "apollo-link-timeout";

import createUploadLink from "./uploadLink";
import config from "../config";
import {TOKEN_COOKIE_NAME} from "../constants";
import {getLogger} from "../logging";
import {parseCookie} from "../utils";

const USE_BATCHING = false;
// const TIMEOUT_SECONDS = 300;

// https://www.apollographql.com/docs/react/caching/cache-configuration
const TYPE_POLICIES: TypePolicies = {
  SearchResult: {
    keyFields: ["type", "id"],
  },
  Update: {
    keyFields: ["modelClass", "modelId"],
  },

  /*
  // Ref: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
  Watch: {
    fields: {
      facilities: {
        merge(existing: any[], incoming: any[], {readField, mergeObjects}) {
          const merged: any[] = existing ? existing.slice(0) : [];
          return merged;
        },
      },
    },
  },
  */

  // Queries
  EntitiesQuery: {
    keyFields: [], // Singleton
  },
  FeaturesQuery: {
    keyFields: [], // Singleton
  },
  SearchQuery: {
    keyFields: [], // Singleton
  },
  SubscriptionsQuery: {
    keyFields: [], // Singleton
  },
  UsersQuery: {
    keyFields: [], // Singleton
  },
  WatchesQuery: {
    keyFields: [], // Singleton
  },

  // Mutations
  ChatMutation: {
    keyFields: [], // Singleton
  },
  EntitiesMutation: {
    keyFields: [], // Singleton
  },
  SubscriptionsMutation: {
    keyFields: [], // Singleton
  },
  UploadsMutation: {
    keyFields: [], // Singleton
  },
  UsersMutation: {
    keyFields: [], // Singleton
  },
  WatchesMutation: {
    keyFields: [], // Singleton
  },
};

const log = getLogger(__filename);

const ssrMode = typeof window === "undefined";
let httpLink;

const linkOptions = {
  // Must be an absolute URL so that this works on SSR
  uri: config.graphqlUrl,
  credentials: "include",
  fetch,
};

if (USE_BATCHING) {
  httpLink = new BatchHttpLink({
    batchMax: 10, // No more than 10 operations per batch
    batchInterval: 10, // Wait no more than 10ms after first batched operation
    ...linkOptions,
  });
} else {
  httpLink = new HttpLink({
    ...linkOptions,
  });
}

interface ClientWithOnReconnected extends Client {
  onReconnected(cb: () => void): () => void;
}

// GraphQL WS client that reconnects on disconnection
// Source: https://the-guild.dev/graphql/ws/recipes#client-usage-with-reconnect-listener
function createClientWithOnReconnected(
  options: ClientOptions
): ClientWithOnReconnected {
  let abruptlyClosed = false;
  const reconnectedCbs: (() => void)[] = [];

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      closed: event => {
        log.error("Subscription client disconnected");
        options.on?.closed?.(event);
        // non-1000 close codes are abrupt closes
        if ((event as CloseEvent).code !== 1000) {
          abruptlyClosed = true;
        }
      },
      connected: (...args) => {
        options.on?.connected?.(...args);
        // if the client abruptly closed, this is then a reconnect
        if (abruptlyClosed) {
          abruptlyClosed = false;
          for (const cb of reconnectedCbs) {
            cb();
          }
        }
      },
    },
  });

  return {
    ...client,
    onReconnected: cb => {
      reconnectedCbs.push(cb);
      return () => {
        reconnectedCbs.splice(reconnectedCbs.indexOf(cb), 1);
      };
    },
  };
}

let wsLink;
if (typeof window !== "undefined") {
  const wsLinkClient = createClientWithOnReconnected({
    url: config.graphqlWsUrl,
    webSocketImpl: WebSocket,
    connectionParams: () => {
      const cookies = parseCookie(document.cookie);
      const token = cookies[TOKEN_COOKIE_NAME];
      if (token) {
        return {
          authToken: token,
        };
      } else {
        return {};
      }
    },
  });

  wsLinkClient.onReconnected(() => {
    log.info("Subscription client reconnected");
  });

  wsLink = new GraphQLWsLink(wsLinkClient);
}

const authContext = setContext(async (_, {headers}) => {
  const nextHeaders = {...headers};

  if (typeof document !== "undefined") {
    const cookies = parseCookie(document.cookie);
    const token = cookies[TOKEN_COOKIE_NAME];
    if (token) {
      nextHeaders.Authorization = `Bearer ${token}`;
    }
  }

  return {headers: nextHeaders};
});

const connections: {[key: string]: AbortController} = {};

// Source: https://stackoverflow.com/questions/59245966/stop-pending-requests-with-apollo-client-hooks/63891561#63891561
// From feature request: https://github.com/apollographql/apollo-client/issues/8858
const cancelRequestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      const context = operation.getContext();

      const connectionHandle = forward(operation).subscribe({
        next: (...arg) => observer.next(...arg),
        error: (...arg) => {
          cleanUp();
          observer.error(...arg);
        },
        complete: (...arg) => {
          cleanUp();
          observer.complete(...arg);
        },
      });

      const cleanUp = () => {
        connectionHandle?.unsubscribe();
        delete connections[context.requestTrackerId];
      };

      if (context.requestTrackerId) {
        const controller = new AbortController();
        controller.signal.onabort = cleanUp;
        operation.setContext({
          ...context,
          fetchOptions: {
            signal: controller.signal,
            ...context?.fetchOptions,
          },
        });

        if (connections[context.requestTrackerId]) {
          // If a controller exists, that means this operation should be aborted.
          connections[context.requestTrackerId].abort();
        }

        connections[context.requestTrackerId] = controller;
      }

      return connectionHandle;
    })
);

// FIXME: This link interferes with the cancelRequestLink, probably because
//        it's blindly replacing the fetchOptions.signal that was installed
// const timeoutLink = new ApolloLinkTimeout(TIMEOUT_SECONDS * 1000);

let terminatingLink;
if (typeof window !== "undefined") {
  terminatingLink = createUploadLink({
    ...linkOptions,
  });
} else {
  terminatingLink = httpLink;
}

// Send subscriptions to wsLink and everything else to httpLink
let splitLink;
if (wsLink) {
  splitLink = split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    terminatingLink
  );
}

const link = ApolloLink.from([
  // timeoutLink,
  authContext,
  cancelRequestLink,
  splitLink || terminatingLink,
]);

export const cache = new InMemoryCache({
  typePolicies: TYPE_POLICIES,
});

const client = new ApolloClient({
  link,
  ssrMode,
  cache,
  connectToDevTools: true, // (config.env !== 'production'),
  queryDeduplication: false, // Necessary for cancelRequestLink to work
  defaultOptions: {
    watchQuery: {
      // fetchPolicy: 'no-cache',
      fetchPolicy: "cache-and-network",
    },
  },
});

/**
 * Query GraphQL server with Apollo client, adding the session cookie
 * to the request (which it gets from the Next.js request context).
 *
 * @param nextContext Next.js request context.
 * @param queryOpts Apollo client query options.
 * @param apolloClient Apollo client to query.
 */
export function queryWithNextContext(
  nextContext: {[key: string]: any},
  queryOpts: any,
  apolloClient?: any
): Promise<any> {
  queryOpts ||= {};
  apolloClient ||= client;

  const cookies = nextContext.req?.cookies || {};
  const token = cookies[TOKEN_COOKIE_NAME];

  if (token) {
    queryOpts.context = {
      ...(queryOpts.context || {}),
      headers: {
        ...(queryOpts.context?.headers || {}),
        // Pass auth token cookie along to API
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return apolloClient.query(queryOpts);
}

export default client;
