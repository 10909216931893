import {gql} from "@apollo/client";

import * as fragments from "./fragments";

export const SIGNUP = gql`
  mutation SignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $captchaResponse: String!
    $reason: String
    $referral: String
    $acceptedTerms: Boolean!
  ) {
    users {
      signUp(
        input: {
          email: $email
          firstName: $firstName
          lastName: $lastName
          captchaResponse: $captchaResponse
          reason: $reason
          referral: $referral
          acceptedTerms: $acceptedTerms
        }
      ) {
        ... on SignUpSuccessResult {
          userId
          email
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $token: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    users {
      resetPassword(
        input: {
          token: $token
          newPassword: $newPassword
          newPasswordConfirmation: $newPasswordConfirmation
        }
      ) {
        ... on ResetPasswordSuccessResult {
          success
          email
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation ResetPasswordRequest($email: String!, $captchaResponse: String!) {
    users {
      resetPasswordRequest(
        input: {email: $email, captchaResponse: $captchaResponse}
      ) {
        ... on ResetPasswordSuccessResult {
          success
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  ${fragments.AUTHENTICATED_USER_FIELDS}

  mutation UpdateUserSettings(
    $firstName: String
    $lastName: String
    $email: String
    $oldPassword: String
    $newPassword: String
    $partialSettingsJson: String
  ) {
    users {
      updateSettings(
        input: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          oldPassword: $oldPassword
          newPassword: $newPassword
          partialSettingsJson: $partialSettingsJson
        }
      ) {
        ...AuthenticatedUserFields
      }
    }
  }
`;

export const UPDATE_UNIT = gql`
  ${fragments.UNIT_FIELDS}

  mutation UpdateUnit(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $useType: String
    $name: String
    $loopName: String
    $location: LocationInput
    $peopleCapacity: IntRangeInput
    $rules: String
    $isGroup: Boolean
    $isReservable: Boolean
    $isAdaAccessible: Boolean
    $petsAllowed: Boolean
    $maxVehicles: Int
    $maxVehicleLengthFeet: Int
    $facilityId: Int
    $isEquipmentMandatory: Boolean
    $allowedEquipmentIds: [Int!]
    $isActive: Boolean
  ) {
    entities {
      updateUnit(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          useType: $useType
          name: $name
          loopName: $loopName
          location: $location
          peopleCapacity: $peopleCapacity
          rules: $rules
          isGroup: $isGroup
          isReservable: $isReservable
          isAdaAccessible: $isAdaAccessible
          petsAllowed: $petsAllowed
          maxVehicles: $maxVehicles
          maxVehicleLengthFeet: $maxVehicleLengthFeet
          facilityId: $facilityId
          isEquipmentMandatory: $isEquipmentMandatory
          allowedEquipmentIds: $allowedEquipmentIds
          isActive: $isActive
        }
      ) {
        ...UnitFields
      }
    }
  }
`;

export const UPDATE_FACILITY = gql`
  ${fragments.FACILITY_FIELDS}

  mutation UpdateFacility(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $name: String
    $url: String
    $bookingUrl: String
    $reservationUrl: String
    $mapUrl: String
    $checkinHour: Int
    $checkoutHour: Int
    $description: String
    $descriptionHtml: String
    $feeDescription: String
    $rules: String
    $directions: String
    $stayLimit: String
    $phone: String
    $email: String
    $isReservable: Boolean
    $isAdaAccessible: Boolean
    $location: LocationInput
    $parkId: Int
    $organizationId: Int
    $isActive: Boolean
  ) {
    entities {
      updateFacility(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          name: $name
          url: $url
          bookingUrl: $bookingUrl
          reservationUrl: $reservationUrl
          mapUrl: $mapUrl
          checkinHour: $checkinHour
          checkoutHour: $checkoutHour
          description: $description
          descriptionHtml: $descriptionHtml
          feeDescription: $feeDescription
          rules: $rules
          directions: $directions
          stayLimit: $stayLimit
          phone: $phone
          email: $email
          isReservable: $isReservable
          isAdaAccessible: $isAdaAccessible
          location: $location
          parkId: $parkId
          organizationId: $organizationId
          isActive: $isActive
        }
      ) {
        ...FacilityFields
      }
    }
  }
`;

export const UPDATE_PARK = gql`
  ${fragments.PARK_FIELDS}

  mutation UpdatePark(
    $id: Int!
    $unsetFields: [String!]
    $name: String
    $description: String
    $descriptionHtml: String
    $feeDescription: String
    $directions: String
    $stayLimit: String
    $phone: String
    $email: String
    $reservationUrl: String
    $mapUrl: String
    $isReservable: Boolean
    $location: LocationInput
    $organizationId: Int
    $isActive: Boolean
  ) {
    entities {
      updatePark(
        input: {
          id: $id
          unsetFields: $unsetFields
          name: $name
          description: $description
          descriptionHtml: $descriptionHtml
          feeDescription: $feeDescription
          directions: $directions
          stayLimit: $stayLimit
          phone: $phone
          email: $email
          reservationUrl: $reservationUrl
          mapUrl: $mapUrl
          isReservable: $isReservable
          location: $location
          organizationId: $organizationId
          isActive: $isActive
        }
      ) {
        ...ParkFields
      }
    }
  }
`;

export const UPDATE_MEDIA = gql`
  ${fragments.MEDIA_FIELDS}

  mutation UpdateMedia(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $title: String
    $subtitle: String
    $description: String
    $credits: String
    $url: String
    $width: Int
    $height: Int
    $isActive: Boolean
  ) {
    entities {
      updateMedia(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          title: $title
          subtitle: $subtitle
          description: $description
          credits: $credits
          url: $url
          width: $width
          height: $height
          isActive: $isActive
        }
      ) {
        ...MediaFields
      }
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  ${fragments.ADDRESS_FIELDS}

  mutation UpdateAddress(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $street: [String!]
    $city: String
    $state: String
    $zip: String
    $country: String
    $isActive: Boolean
  ) {
    entities {
      updateAddress(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          street: $street
          city: $city
          state: $state
          zip: $zip
          country: $country
          isActive: $isActive
        }
      ) {
        ...AddressFields
      }
    }
  }
`;

export const UPDATE_CLOSURE = gql`
  ${fragments.CLOSURE_FIELDS}

  mutation UpdateClosure(
    $id: Int!
    $unsetFields: [String!]
    $beginsAt: DateTime
    $endsAt: DateTime
    $reason: String
    $isActive: Boolean
  ) {
    entities {
      updateClosure(
        input: {
          id: $id
          unsetFields: $unsetFields
          beginsAt: $beginsAt
          endsAt: $endsAt
          reason: $reason
          isActive: $isActive
        }
      ) {
        ...ClosureFields
      }
    }
  }
`;

export const REFRESH_UNIT = gql`
  ${fragments.UNIT_FIELDS}

  mutation RefreshUnit($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshUnit(unitId: $id, clearOverrides: $clearOverrides) {
        ...UnitFields
      }
    }
  }
`;

export const REFRESH_FACILITY = gql`
  ${fragments.FACILITY_FIELDS}

  mutation RefreshFacility($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshFacility(facilityId: $id, clearOverrides: $clearOverrides) {
        ...FacilityFields
      }
    }
  }
`;

export const REFRESH_PARK = gql`
  ${fragments.PARK_FIELDS}

  mutation RefreshPark($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshPark(parkId: $id, clearOverrides: $clearOverrides) {
        ...ParkFields
      }
    }
  }
`;

export const REFRESH_MEDIA = gql`
  ${fragments.MEDIA_FIELDS}

  mutation RefreshMedia($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshMedia(mediaId: $id, clearOverrides: $clearOverrides) {
        ...MediaFields
      }
    }
  }
`;

export const REFRESH_ADDRESS = gql`
  ${fragments.ADDRESS_FIELDS}

  mutation RefreshAddress($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshAddress(addressId: $id, clearOverrides: $clearOverrides) {
        ...AddressFields
      }
    }
  }
`;

export const REFRESH_CLOSURE = gql`
  ${fragments.CLOSURE_FIELDS}

  mutation RefreshClosure($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshClosure(closureId: $id, clearOverrides: $clearOverrides) {
        ...ClosureFields
      }
    }
  }
`;

export const SEND_CHAT_MESSAGE = gql`
  ${fragments.CHAT_MESSAGE}

  mutation SendChatMessage($message: String!) {
    chat {
      sendMessage(input: {message: $message}) {
        ...ChatMessageFields
      }
    }
  }
`;

export const UPLOAD_MAPPING = gql`
  mutation UploadMapping($name: String!, $file: Upload!) {
    uploads {
      uploadMapping(name: $name, file: $file) {
        uploadId
        fileName
        fileFormat
        rows {
          id
          name
          description
          location {
            latitude
            longitude
          }
          unit {
            id
            slug
            name
            loopName
            facility {
              id
              slug
              name
              park {
                id
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($planId: String!) {
    subscriptions {
      create(planId: $planId) {
        subscriptionId
        clientSecret
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subscriptionId: String!) {
    subscriptions {
      cancel(subscriptionId: $subscriptionId)
    }
  }
`;

export const CREATE_WATCH = gql`
  ${fragments.WATCH}

  mutation CreateWatch(
    $name: String
    $excludeGroup: Boolean! = false
    $minPeople: Int
    $maxPeople: Int
    $onlyPetsAllowed: Boolean! = false
    $onlyAccessible: Boolean! = false
    $excludeMandatoryEquipment: Boolean! = false
    $maxVehicleLengthFeet: Int
    $vehicles: Int
    $minDate: Date!
    $maxDate: Date!
    $nights: Int
    $alertsEnabled: Boolean!
    $parkIds: [Int!]
    $facilityIds: [Int!]
    $unitIds: [Int!]
  ) {
    watches {
      create(
        input: {
          name: $name
          filter: {
            dates: {min: $minDate, max: $maxDate}
            nights: $nights
            excludeGroup: $excludeGroup
            people: {min: $minPeople, max: $maxPeople}
            onlyPetsAllowed: $onlyPetsAllowed
            onlyAccessible: $onlyAccessible
            excludeMandatoryEquipment: $excludeMandatoryEquipment
            maxVehicleLengthFeet: $maxVehicleLengthFeet
            vehicles: $vehicles
          }
          alertsEnabled: $alertsEnabled
          parkIds: $parkIds
          facilityIds: $facilityIds
          unitIds: $unitIds
        }
      ) {
        ...WatchFields
      }
    }
  }
`;

export const UPDATE_WATCH = gql`
  ${fragments.WATCH}

  mutation UpdateWatch(
    $watchId: Int!
    $name: String
    $unsetFields: [String!]
    $excludeGroup: Boolean
    $minPeople: Int
    $maxPeople: Int
    $onlyPetsAllowed: Boolean
    $onlyAccessible: Boolean
    $excludeMandatoryEquipment: Boolean
    $maxVehicleLengthFeet: Int
    $vehicles: Int
    $minDate: Date
    $maxDate: Date
    $nights: Int
    $alertsEnabled: Boolean
    $parkIds: [Int!]
    $facilityIds: [Int!]
    $unitIds: [Int!]
  ) {
    watches {
      update(
        watchId: $watchId
        unsetFields: $unsetFields
        input: {
          name: $name
          filter: {
            dates: {min: $minDate, max: $maxDate}
            nights: $nights
            excludeGroup: $excludeGroup
            people: {min: $minPeople, max: $maxPeople}
            onlyPetsAllowed: $onlyPetsAllowed
            onlyAccessible: $onlyAccessible
            excludeMandatoryEquipment: $excludeMandatoryEquipment
            maxVehicleLengthFeet: $maxVehicleLengthFeet
            vehicles: $vehicles
          }
          alertsEnabled: $alertsEnabled
          parkIds: $parkIds
          facilityIds: $facilityIds
          unitIds: $unitIds
        }
      ) {
        ...WatchFields
      }
    }
  }
`;

export const CANCEL_WATCH = gql`
  ${fragments.WATCH}

  mutation CancelWatch($watchId: Int!) {
    watches {
      cancel(watchId: $watchId) {
        ...WatchFields
      }
    }
  }
`;

export const CHECK_FACILITY = gql`
  ${fragments.FACILITY_FIELDS}

  mutation CheckFacility(
    $facilityId: Int!
    $beginDate: Date!
    $endDate: Date!
  ) {
    entities {
      checkFacility(
        facilityId: $facilityId
        beginDate: $beginDate
        endDate: $endDate
      ) {
        ...FacilityFields
      }
    }
  }
`;
